export function expandBounds(
  bounds: google.maps.LatLngBounds,
  offset = 0.001,
) {
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  return bounds
    .extend({ lat: sw.lat() - offset, lng: sw.lng() - offset })
    .extend({ lat: ne.lat() + offset, lng: ne.lng() + offset })
}
