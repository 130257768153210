import { useLazyQuery } from '@apollo/client'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { getBoundsOfDistance, getCenter, getDistance } from 'geolib'
import { useCallback, useState } from 'react'
import { graphql } from '~/gql'

const spotsDocument = graphql(`
  query SpotsQuery($bbox: [Float!]) {
    spots(bbox: $bbox) {
      ...SpotsItem
    }
  }
`)

export function useSpotsQuery() {
  const [getSpots, { loading, data }] = useLazyQuery(spotsDocument)
  const corelib = useMapsLibrary('core')
  const [previousBbox, setPreviousBbox] = useState<google.maps.LatLngBounds | undefined>(undefined)

  const reloadFunc = useCallback(
    (cache = true, bbox?: number[]) => {
      getSpots({
        variables: bbox ? { bbox } : {},
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
      })
    },
    [getSpots],
  )

  const reloadFuncWithBbox = useCallback(
    (cache: boolean, bounds?: google.maps.LatLngBounds | undefined, mag: number = 1.2) => {
      if (!corelib) return
      if (!bounds) return reloadFunc(cache)

      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const center = getCenter([
        { latitude: sw.lat(), longitude: sw.lng() },
        { latitude: ne.lat(), longitude: ne.lng() },
      ])
      if (!center) return

      // mag倍の広さを取得
      const distance = getDistance(
        { latitude: sw.lat(), longitude: sw.lng() },
        { latitude: ne.lat(), longitude: ne.lng() },
      )
      const expandedBounds = getBoundsOfDistance(center, distance * mag)
      const bbox = [
        expandedBounds[0].latitude,
        expandedBounds[0].longitude,
        expandedBounds[1].latitude,
        expandedBounds[1].longitude,
      ]
      const exSw = { lat: bbox[0], lng: bbox[1] }
      const exNe = { lat: bbox[2], lng: bbox[3] }
      reloadFunc(cache, bbox)
      setPreviousBbox(() => new corelib.LatLngBounds(exSw, exNe))
    },
    [corelib, reloadFunc],
  )

  return { reloadFuncWithBbox, loading, data, previousBbox }
}
