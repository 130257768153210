import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { DetailPanel } from '~/components/DetailPanel'
import { graphql } from '~/gql'
import { useMutation, useQuery } from '@apollo/client'
import { Spinner } from 'flowbite-react'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { logEvent } from 'firebase/analytics'
import { SelectedSpot } from '~/models/SelectedSpotType.ts'
import { firebaseAnalytics } from '~/utils/firebase'
import { getRouteUrl } from '~/utils/googleMap'
import { Latlng } from '~/models/PositionType'

const spotDocument = graphql(`
  query SpotQuery($code: String!) {
    spot(code: $code) {
      budgetDinner {
        max
        min
      }
      budgetLunch {
        max
        min
      }
      code
      latitude
      longitude
      name
      phoneNumber
      homepageUrl
      isOpen
      imakoAdvice
      crowdedLevel
      addressfull
      businessHoursNote
      smoking
      seatsText
      todayBusinessHour {
        endTime
        startTime
      }
      spotCrowdInformationStatuses {
        lastCheckedOn
      }
      spotCrowdInformations {
        crowdedMatrix {
          endTime
          startTime
          key
        }
        crowdLevel
      }
      spotPhotos {
        imageUrl
        originalFrom
        originalUrl
        isImage
      }
    }
  }
`)

const reviewMutation = graphql(`
  mutation SpotReviewLevelCreate($input: SpotReviewCreateInput!) {
    spotReviewCreate(input: $input) {
      spotReview {
        comment
        crowdedLevel
        name
        visitedOn
        visitedTime
      }
    }
  }
`)

function Spot() {
  const { spotCode } = useParams()
  const { data, loading } = useQuery(spotDocument, {
    variables: { code: spotCode || '' },
  })
  const spot = data?.spot
  const [createReview] = useMutation(reviewMutation)
  const { position, setSelectedSpot } = useOutletContext<{
    position: { lat: number; lng: number }
    setSelectedSpot: Dispatch<SetStateAction<SelectedSpot | undefined>>
  }>()

  function getVisitedTime(now: Date) {
    const hour = now.getHours()
    if (hour >= 22 && hour < 5) {
      return 6
    } else if (hour < 12) {
      return 1
    } else if (hour < 14) {
      return 2
    } else if (hour < 16) {
      return 3
    } else if (hour < 18) {
      return 4
    } else {
      return 5
    }
  }

  useEffect(() => {
    logEvent(firebaseAnalytics, 'select_content', {
      content_type: 'spot',
      item_id: spotCode,
    })
  }, [])

  const onSubmit = (d: { crowdedLevel: string }) => {
    const now = new Date()

    createReview({
      variables: {
        input: {
          spotReviewInput: {
            spotCode: spotCode || '',
            name: 'dummy',
            comment: '',
            visitedOn: format(now, 'yyyy-MM-dd'),
            visitedTime: getVisitedTime(now),
            crowdedLevel: d.crowdedLevel,
            reviewType: 'crowded_level',
          },
        },
      },
    })
  }

  const onShare = () => {
    logEvent(firebaseAnalytics, 'share', {
      content_type: 'spot',
      item_id: spotCode,
    })
  }

  const onNavigation = (from: Latlng, to: Latlng) => {
    logEvent(firebaseAnalytics, 'navigation', {
      item_id: spotCode,
    })
    const routeUrl = getRouteUrl(from, to)
    window.location.replace(routeUrl)
  }

  const onPhone = () => {
    logEvent(firebaseAnalytics, 'phone', {
      item_id: spotCode,
    })
  }

  useEffect(() => {
    if (spot)
      setSelectedSpot({
        code: spot.code,
        lat: spot.latitude,
        lng: spot.longitude,
      })
    return () => {
      setSelectedSpot(undefined)
    }
  }, [spot])

  return (
    <>
      <div className="absolute md:w-96 md:h-full md:top-10 md:rounded-r-lg md:shadow-xl w-full left-0 bottom-0 h-3/4 bg-white delay-75 rounded-t-lg overflow-y-auto">
        {loading || !data ? (
          <div
            className={`top-50 left-0 w-full h-full z-10 overflow-y-visible flex items-center justify-center`}
          >
            <Spinner
              aria-label="Loading spot data"
              size="xl"
              className="z-20"
              color="success"
            />
          </div>
        ) : (
          <DetailPanel
            onSubmit={onSubmit}
            onShare={onShare}
            onNavigation={onNavigation}
            onPhone={onPhone}
            position={position}
            spot={data.spot}
          />
        )}
      </div>
      <Outlet />
    </>
  )
}

export default Spot
