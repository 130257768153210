import { Carousel, Modal, Flowbite } from 'flowbite-react'
import { useState } from 'react'
import type { CustomFlowbiteTheme } from 'flowbite-react'

type SpotPhotoType = {
  spotPhotos: {
    imageUrl?: string | null | undefined
    isImage: boolean
    originalUrl?: string | null
    originalFrom?: string | null
  }[]
}

type SpotPhotoModalType = {
  imageUrl: string
  isImage: boolean
  originalUrl: string
  originalFrom: string
}

const customTheme: CustomFlowbiteTheme = {
  modal: {
    content: {
      base: 'relative h-full w-full p-4 h-auto',
    },
  },
}

function SpotPhotoWithModal({
  isImage,
  imageUrl,
  originalUrl,
  originalFrom,
}: SpotPhotoModalType) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      {isImage ? (
        <img
          className="object-cover object-center w-full h-full"
          src={imageUrl}
          loading="lazy"
          onClick={() => setOpenModal(true)}
        />
      ) : (
        <video
          autoPlay
          playsInline
          muted
          loop
          onClick={() => setOpenModal(true)}
        >
          <source src={imageUrl} />
        </video>
      )}
      <Flowbite theme={{ theme: customTheme }}>
        <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div className="flex justify-center">
              {isImage ? (
                <img src={imageUrl} loading="lazy" />
              ) : (
                <video controls autoPlay playsInline muted>
                  <source src={imageUrl} />
                </video>
              )}
            </div>
            <div className="text-xs text-right py-3">
              {originalFrom === 'original' ? (
                '弊社画像'
              ) : (
                <a
                  href={originalUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  参照元はこちらから
                </a>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </Flowbite>
    </>
  )
}

export function SpotPhoto({ spotPhotos }: SpotPhotoType) {
  return (
    <div className="h-56">
      <Carousel slide={false}>
        {spotPhotos
          .filter((spotPhoto) => !!spotPhoto.imageUrl)
          .map((spotPhoto, index) => {
            return (
              <SpotPhotoWithModal
                isImage={spotPhoto.isImage}
                imageUrl={spotPhoto.imageUrl ?? ''}
                originalUrl={spotPhoto.originalUrl ?? ''}
                originalFrom={spotPhoto.originalFrom ?? ''}
                key={index}
              />
            )
          })}
      </Carousel>
    </div>
  )
}
