import { Link, useNavigate, useParams } from "react-router-dom";
import { SpotReviewForm, Inputs } from "../../components/SpotReviewForm";
import { graphql } from "../../gql";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { format } from "date-fns";

const mutationDocument = graphql(`
mutation SpotReviewDetailCreate($input: SpotReviewCreateInput!) {
  spotReviewCreate(input: $input) {
    spotReview {
      comment
      crowdedLevel
      visitedOn
      visitedTime
    }
  }
}
`)

function Review() {
  const params = useParams()
  const [createReview, { data, loading, error }] = useMutation(mutationDocument)
  const navigate = useNavigate()

  const onSubmit = (input: Inputs) => {
    const visitedOnStr = format(input.visitedOn, "yyyy-MM-dd")
    createReview({ variables: { input: { spotReviewInput: { 
      spotCode: input.spotCode,
      comment: input.comment || "",
      crowdedLevel: input.crowdedLevel,
      visitedOn: visitedOnStr,
      visitedTime: parseInt(input.visitedTime),
      reviewType: 'crowded_detail'
    }}}})
  }

  useEffect(() => {
    if (!data || loading || error) return

    navigate(`/spots/${params.spotCode}`)
  }, [data, loading, error, navigate, params.spotCode])

  return (
    <div className={`w-full absolute left-0 top-10 h-full bg-white`}>
      <Link to={`/spots/${params.spotCode}`} className="m-3 mx-5">&lt;&lt; お店情報に戻る</Link>
      <SpotReviewForm spotCode={params.spotCode || ""} onSubmit={onSubmit} loading={loading} />
    </div>
  );
}

export default Review