import { Button } from 'flowbite-react'
import { useState } from 'react'
import { useForm, UseFormRegister } from 'react-hook-form'

interface FormInput {
  crowdedLevel: string
}

type CrowdedOptionProp = {
  register: UseFormRegister<FormInput>
  watchCrowdedLevel: string
  crowdedLevel: string
  crowdedLevelLabel: string
}

type CrowdedReviewProp = {
  onSubmit: (data: FormInput) => void
}

function CrowdedOption({
  register,
  watchCrowdedLevel,
  crowdedLevel,
  crowdedLevelLabel,
}: CrowdedOptionProp) {
  return (
    <label htmlFor={`crowdedLevel-${crowdedLevel}`}>
      <input
        id={`crowdedLevel-${crowdedLevel}`}
        className="invisible"
        type="radio"
        {...register('crowdedLevel', { required: true })}
        value={crowdedLevel}
      />
      <span
        className={`
          inline-block
          text-center
          text-nowrap
          w-16
          p-2
          text-xs
          rounded-lg
          ${watchCrowdedLevel == crowdedLevel ? 
            'bg-primary text-white' : 
            'bg-white border border-primary'}
          `}
      >
        {crowdedLevelLabel}
      </span>
    </label>
  )
}

export function CrowdedReviewForm({ onSubmit }: CrowdedReviewProp) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<FormInput>()

  const watchCrowdedLevel = watch('crowdedLevel')

  return (
    <div className="rounded-lg bg-primary/[.2] p-2 text-xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="text-primary font-semibold text-center text-sm">
          現在、並んでいますか？
        </h3>
        <div className="flex justify-center items-center mt-5">
          <CrowdedOption
            register={register}
            watchCrowdedLevel={watchCrowdedLevel}
            crowdedLevel="noCrowded"
            crowdedLevelLabel="待ちなし"
          />
          <CrowdedOption
            register={register}
            watchCrowdedLevel={watchCrowdedLevel}
            crowdedLevel="littleCrowded"
            crowdedLevelLabel="1-3組"
          />
          <CrowdedOption
            register={register}
            watchCrowdedLevel={watchCrowdedLevel}
            crowdedLevel="crowded"
            crowdedLevelLabel="4-7組"
          />
          <CrowdedOption
            register={register}
            watchCrowdedLevel={watchCrowdedLevel}
            crowdedLevel="veryCrowded"
            crowdedLevelLabel="8組以上"
          />
        </div>
        <div className="justify-center flex mt-5">
          <Button
            type="submit"
            className="bg-primary px-10"
            size="xs"
            disabled={!isValid}
          >
            送信
          </Button>
        </div>
      </form>
    </div>
  )
}

function CrowdedReviewThanks() {
  return (
    <>
      <div className="rounded-lg bg-primary px-2 py-3 text-white">
        <h1 className="text-sm text-center font-bold">回答を記録しました✨</h1>
        <p className="text-xs text-center">ご回答ありがとうございました。混雑情報に反映されます。</p>
        <p className="text-xs text-center">情報を入力して、混雑マップを完成させよう！</p>
      </div>
    </>
  )
}

export function CrowdedReview({ onSubmit }: CrowdedReviewProp) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const onSubmitWithState = (data: FormInput) => {
    onSubmit(data)
    setIsSubmitted(true)
  }

  return (
    <>
      {isSubmitted ? (
        <CrowdedReviewThanks />
      ) : (
        <CrowdedReviewForm onSubmit={onSubmitWithState} />
      )}
    </>
  )
}
