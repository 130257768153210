import { QueryRef, useReadQuery } from '@apollo/client'
import { graphql } from '~/gql'
import { ToastContainer } from 'react-toastify'
import { MapContainer } from '~/components/MapContainer'
import { Outlet, useLoaderData, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Header } from '~/components/Header'
import { SelectedSpot } from '~/models/SelectedSpotType.ts'
import { preloadQuery } from '~/utils/apiClient'
import { firebaseAnalytics, waitUser } from '~/utils/firebase'

import 'react-toastify/dist/ReactToastify.css'
import { MeQuery } from '~/gql/graphql'
import { setUserId, logEvent } from 'firebase/analytics'
import { useNotification } from '~/hooks/useNotification'
import { useFirstEnquete } from '~/hooks/useFirstEnquete'
import { useOnboarding } from '~/hooks/useOnboarding'
import { useDeviceOrientation } from '~/hooks/useDeviceOrientation'
import { useGeolocation } from '~/hooks/useGeolocation'

const userDocument = graphql(`
  query Me {
    me {
      code
      userAttribute {
        id
      }
      completeOnboarding
    }
  }
`)

export async function loader() {
  await waitUser
  return preloadQuery(userDocument).toPromise()
}

function Root() {
  const loaderQueryRef = useLoaderData() as QueryRef<MeQuery, unknown>
  const { data: loadData } = useReadQuery(loaderQueryRef)
  const { NotificationRequestModal, initNotification } = useNotification()
  const { Enquete, setShowFirstEnquete } = useFirstEnquete(false)
  const { OnboardingModal, setShowOnboarding } = useOnboarding(false)
  const [searchParams] = useSearchParams()
  const { initGeo, removeGeo, currentPosition } = useGeolocation()
  const {
    initDeviceOrientation,
    currentHeading,
    watchDeviceOrientation,
    removeDeviceOrientation,
  } = useDeviceOrientation()
  const [selectedSpot, setSelectedSpot] = useState<SelectedSpot | undefined>(
    undefined,
  )

  useEffect(() => {
    setShowFirstEnquete(false)
  }, [loadData])

  useEffect(() => {
    setShowOnboarding(!loadData.me?.completeOnboarding)
  }, [loadData])

  useEffect(() => {
    if (!loadData.me?.code) return

    setUserId(firebaseAnalytics, loadData.me?.code)
    return () => {
      setUserId(firebaseAnalytics, null)
    }
  }, [loadData])

  const isCompleteOnboarding = loadData.me?.completeOnboarding

  useEffect(() => {
    if (!isCompleteOnboarding) return
    const watchId = initGeo((opts) => {
      logEvent(firebaseAnalytics, 'geolocation', opts)
    })

    return () => {
      if (watchId) removeGeo(watchId)
    }
  }, [isCompleteOnboarding])

  useEffect(() => {
    if (!isCompleteOnboarding) return
    watchDeviceOrientation()

    return () => {
      removeDeviceOrientation()
    }
  }, [isCompleteOnboarding])

  useEffect(() => {
    if (!isCompleteOnboarding) return
    initNotification()
  }, [isCompleteOnboarding])

  const handleRequestPermission = (status: string) => {
    logEvent(firebaseAnalytics, 'deviceorientationpermission', {
      status,
    })
  }

  const handleOnboardingStart = () => {
    initGeo((opts) => {
      logEvent(firebaseAnalytics, 'geolocation', opts)
    })
    initDeviceOrientation(handleRequestPermission)
    initNotification()
  }

  const onMoveCenter = () => {
    logEvent(firebaseAnalytics, 'move_center', {})
    // もし初回オンボーディング経過後にとれなかったら再度取得する
    initDeviceOrientation(handleRequestPermission)
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        theme="light"
        hideProgressBar
      />
      <Header />
      <MapContainer
        defaultCenter={searchParams.get('c')}
        defaultZoom={parseFloat(searchParams.get('z') ?? '18')}
        currentPosition={currentPosition}
        currentHeading={currentHeading}
        onMoveCenter={onMoveCenter}
        onReloadCurrentTime={() =>
          logEvent(firebaseAnalytics, 'reload_current_time', {})
        }
        selectedSpot={selectedSpot}
      >
        <Outlet
          context={{
            position: currentPosition,
            setSelectedSpot: setSelectedSpot,
          }}
        />
      </MapContainer>
      {Enquete}
      {OnboardingModal(handleOnboardingStart)}
      {NotificationRequestModal}
    </>
  )
}

export default Root
