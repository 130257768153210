import { APIProvider } from '@vis.gl/react-google-maps'
import { PropsWithChildren } from 'react'
import { SelectedSpot } from '~/models/SelectedSpotType.ts'
import { ImakoMap } from '../ImakoMap'
import { Latlng } from '../../models/PositionType'

export function MapContainer({
  defaultCenter,
  defaultZoom,
  currentPosition,
  currentHeading,
  onMoveCenter,
  onReloadCurrentTime,
  selectedSpot,
  children,
}: PropsWithChildren<{
  defaultCenter?: string | null
  defaultZoom?: number
  currentPosition: Latlng | undefined
  currentHeading: number
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void
  selectedSpot?: SelectedSpot
}>) {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? ''}>
      <ImakoMap
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        currentPosition={currentPosition}
        currentHeading={currentHeading}
        onMoveCenter={onMoveCenter}
        onReloadCurrentTime={onReloadCurrentTime}
        selectedSpot={selectedSpot}
      />
      {children}
    </APIProvider>
  )
}
