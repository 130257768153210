import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { graphql } from '~/gql'
import { Onboarding } from '../components/Onboarding'

const completeOnboardingMutationDocument = graphql(`
  mutation CompleteOnboarding(
    $CompleteOnboardingInput: CompleteOnboardingInput!
  ) {
    completeOnboarding(input: $CompleteOnboardingInput) {
      userOnboarding {
        id
        completedAt
      }
    }
  }
`)

export type OnboardingCompleteFunc = () => void

export function useOnboarding(show: boolean) {
  const [completeOnboarding] = useMutation(completeOnboardingMutationDocument)
  const [showOnboarding, setShowOnboarding] = useState(show)

  const OnboardingModal = useCallback((onStartFunc: OnboardingCompleteFunc) => {
    return showOnboarding && <Onboarding onStart={() => {
      completeOnboarding({ variables: { CompleteOnboardingInput: {} } })
      setShowOnboarding(() => false)
      onStartFunc()
    }} />
  }, [showOnboarding])
  return { OnboardingModal, setShowOnboarding }
}
