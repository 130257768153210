import { createBrowserRouter } from "react-router-dom"
import Root, { loader as rootLoader } from "./root"
import Spot from "./spot"
import Review from "./spot/review"
import InfoRevisionSuggestion from "./spot/info_revision_suggestion"
import ErrorPage from "./error-page"
import { Area } from "./area"

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'spots/:spotCode',
        element: <Spot />,
        children: [
          {
            path: 'review',
            element: <Review />
          },
          {
            path: 'suggestion',
            element: <InfoRevisionSuggestion />
          }
        ]
      },
      {
        path: 'area/:area',
        element: <Area />
      }
    ]
  }
])
