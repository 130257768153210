import { Button } from 'flowbite-react'
import { SpotQueryQuery } from '~/gql/graphql.ts'
import { CrowdedLabelWithColor } from '../CrowdedIcon'
import { Pricing } from './Pricing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiamondTurnRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Latlng } from '~/models/PositionType.ts'

export type DetailInfoProps = React.PropsWithChildren<{
  spot: SpotQueryQuery['spot']
  position: Latlng | undefined
  onNavigation?: (from: Latlng, to: Latlng) => void
  onPhone?: () => void
}>

export function DetailInfo({
  spot,
  position,
  onNavigation,
  onPhone,
}: DetailInfoProps) {
  const onNavigationClick = () => {
    if (position && spot && spot.latitude && spot.longitude && onNavigation) {
      onNavigation(position, { lat: spot.latitude, lng: spot.longitude })
    }
  }
  const onPhoneClick = () => {
    if (onPhone) onPhone()
  }

  return (
    <>
      <div className="float-right flex flex-wrap gap-2">
        <Button
          size="xs"
          pill
          href={`tel:${spot.phoneNumber}`}
          className="bg-primary/[.1] font-bold content-center"
          onClick={onPhoneClick}
          disabled={!spot.phoneNumber}
        >
          <div className="text-primary">
            <FontAwesomeIcon icon={faPhone} />
          </div>
        </Button>
        <Button
          size="xs"
          pill
          onClick={onNavigationClick}
          className="bg-primary/[.1] font-bold"
        >
          <div className="text-primary">
            <FontAwesomeIcon icon={faDiamondTurnRight} />
          </div>
        </Button>
      </div>
      <div className="flex items-center">
        <div className="w-full text-xs">
          <Pricing
            kind="lunch"
            min={spot.budgetLunch?.min}
            max={spot.budgetLunch?.max}
          />
          <span className="mr-1" />
          <Pricing
            kind="dinner"
            min={spot.budgetDinner?.min}
            max={spot.budgetDinner?.max}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-full text-xs content-center">
          {spot.isOpen ? (
            <span className="text-primary mr-2 font-bold">現在営業中</span>
          ) : (
            <span className="text-[#FF6C6C] mr-2 font-bold">営業時間外</span>
          )}
          <span className="text-2xs">
            (
            {spot.todayBusinessHour.map((val, index) => (
              <span key={index}>
                {index == 0 ? '' : ','}
                {`${val.startTime} - ${val.endTime}`}
              </span>
            ))}
            )
          </span>
        </div>
      </div>
      {spot.crowdedLevel === 'unknown' || spot.crowdedLevel === 'noData' ? (
        ''
      ) : (
        <div className="flex items-center">
          <div className="font-bold text-xs text-nowrap mr-3">
            現在の混雑状況
          </div>
          <div className="min-w-auto">
            <CrowdedLabelWithColor
              crowdedType={spot.isOpen ? spot.crowdedLevel : 'noBusinessHours'}
            />
          </div>
        </div>
      )}
    </>
  )
}
