import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { FirstEnquete } from '~/components/FirstEnquete'
import { graphql } from '~/gql'

const userAttributeMutationDocument = graphql(`
  mutation Mutation($input: EnqueteCreateInput!) {
    enqueteCreate(input: $input) {
      userAttribute {
        sex
        ageGroup
        nickname
      }
    }
  }
`)

export function useFirstEnquete(showEnquete: boolean) {
  const [showFirstEnquete, setShowFirstEnquete] = useState(showEnquete)
  const [createEnquete] = useMutation(userAttributeMutationDocument)

  const Enquete = showFirstEnquete && (
    <FirstEnquete
      onSubmit={(data) => {
        createEnquete({ variables: { input: { inputs: data } } })
        setShowFirstEnquete(false)
      }}
    />
  )

  return { Enquete, showFirstEnquete, setShowFirstEnquete }
}
