import { useState } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'
import { firebaseGetToken } from '~/utils/firebase'
import { useMutation } from '@apollo/client'
import { NotificationModal } from '~/components/NotificationModal'
import { graphql } from '~/gql'

const pushIdtokenCreateMutationDocument = graphql(`
  mutation PushIdtokenCreate($input: PushIdtokenCreateInput!) {
    pushIdtokenCreate(input: $input) {
      user {
        code
      }
    }
  }
`)

const pushIdtokenDestroyMutationDocument = graphql(`
  mutation PushIdtokenDestroy($input: PushIdtokenDestroyInput!) {
    pushIdtokenDestroy(input: $input) {
      user {
        code
      }
    }
  }
`)

export function useNotification() {
  const [lastPermission, saveLastPermission] = useLocalStorage(
    'request-notification-permission-result',
    'false',
  )
  const [lastPermissionAt, saveLastPermissionAt] = useLocalStorage(
    'request-notification-permission-at',
    '',
  )
  const [lastPushToken, saveLastPushToken] = useLocalStorage(
    'push-token',
    '',
  )
  const [lastFetchGetTokenAt, saveLastFetchGetTokenAt] = useLocalStorage(
    'last-fetch-get-token-at',
    '',
  )
  const [createPushIdtoken] = useMutation(pushIdtokenCreateMutationDocument)
  const [destroyPushIdtoken] = useMutation(pushIdtokenDestroyMutationDocument)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const WAIT_REQUEST_DURATION = 1000 * 60 * 60 * 24 * 7 // 7 days

  const registToken = (token: string) => {
    if (
      lastPushToken === token &&
      lastFetchGetTokenAt &&
      Date.now() - parseInt(lastFetchGetTokenAt) < WAIT_REQUEST_DURATION
    )
      return

    createPushIdtoken({ variables: { input: { input: { token } } } })
    saveLastFetchGetTokenAt(Date.now().toString())
    saveLastPushToken(token)
  }

  const unregistToken = () => {
    if (!lastPushToken) return

    destroyPushIdtoken({ variables: { input: {} } })
    saveLastFetchGetTokenAt('')
    saveLastPushToken('')
  }

  const getNotificationToken = async () => {
    try {
      const token = await firebaseGetToken()
      if (token) {
        return setTimeout(() => {
          registToken(token)
        }, 1000)
      }
      throw new Error('token is empty')
    } catch (_error) {
      saveLastPermission('denied')
      return setTimeout(() => {
        unregistToken()
      }, 1000)
    }
  }

  const initNotification = async () => {
    if (import.meta.env.VITE_ENABLE_PUSH_NOTIFICATION !== 'yes') return
    if (
      lastPermission === 'false' &&
      (!lastPermissionAt ||
        Date.now() - parseInt(lastPermissionAt) > WAIT_REQUEST_DURATION)
    ) {
      requestNotificationPermission()
    } else if (lastPermission === 'granted') {
      ;(async () => {
        return getNotificationToken()
      })()
    } else if (lastPermission === 'denied') {
      // 意図しない場合もあるので、リクエストを希求してもよさそう
      // eg) safariの通知許可要求の変更
      // https://support.apple.com/ja-jp/guide/safari/sfri40734/mac
    }
  }

  const requestNotificationPermission = () => {
    if (
      lastPushToken &&
      lastFetchGetTokenAt &&
      Date.now() - parseInt(lastFetchGetTokenAt) < WAIT_REQUEST_DURATION
    )
      return
    setShowNotificationModal(true)
    saveLastPermissionAt(Date.now().toString())
  }

  const handleNotificationOk = () => {
    ;(async () => {
      const permission = await Notification.requestPermission()

      saveLastPermission(permission.toString())

      if (permission === 'granted') {
        return await getNotificationToken()
      } else {
        return setTimeout(() => {
          unregistToken()
        }, 1000)
      }
    })()
  }

  const handleNotificationCancel = () => {
    saveLastPermission('false')
  }

  const NotificationRequestModal = (
    <NotificationModal
      onOk={handleNotificationOk}
      onCancel={handleNotificationCancel}
      showModal={showNotificationModal}
    />
  )
  return { NotificationRequestModal, initNotification }
}
