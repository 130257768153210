import { initializeApp, getApp, FirebaseApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { firebaseAppOptions } from './firebaseOptions'

const APPNAME = 'imako-crowdmap'
let firebaseApp: FirebaseApp

try {
  firebaseApp = getApp(APPNAME)
} catch {
  firebaseApp = initializeApp(firebaseAppOptions, APPNAME)
}

const firebaseAuth = getAuth(firebaseApp)

const firebaseAnalytics = getAnalytics(firebaseApp)


const firebaseGetToken = async () => {
  try {
    if (!await isSupported()) return

    const firebaseMessaging = getMessaging(firebaseApp)
    const registration = await navigator.serviceWorker.getRegistration()
    return getToken(firebaseMessaging, {
      serviceWorkerRegistration: registration,
      vapidKey: import.meta.env.VITE_FIREBASE_MESSAGE_VAPID_KEY,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

const waitUser = new Promise((resolve, _) => {
  let tryLogin = false
  const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
    if (user === null) {
      tryLogin = true
      signInAnonymously(firebaseAuth)
    } else {
      unsubscribe()
      if (tryLogin) logEvent(firebaseAnalytics, 'login', { method: 'Firebase' })
      resolve(user)
    }
  })
})

export {
  firebaseAuth,
  firebaseApp,
  firebaseAnalytics,
  firebaseGetToken,
  waitUser,
}
