import { Navigate, useParams } from "react-router-dom"

export function Area() {
  const { area } = useParams()

  const centers = area ?{
    ginza: '35.67122,139.76505',
    yurakucho: '35.6750733,139.7624948',
    hibiya: '35.674569,139.7594108',
    omotesando: '35.6652478,139.7104378'
  }[area] : undefined

  return (
    <>
      { centers && <Navigate to={`/?c=${centers}&z=18`} /> }
    </>
  )

}