import {
  Map,
  useMap,
  MapControl,
  ControlPosition,
  MapCameraChangedEvent,
  MapEvent,
} from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Spinner } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons'
import { SelectedSpot } from '~/models/SelectedSpotType.ts'
import { GINZA_CENTER } from '~/utils/const'
import { Latlng } from '~/models/PositionType'
import { ClusteredSpotMarkers } from '~/components/SpotMarker'
import { CurrentPositionMarker } from '~/components/CurrentPositionMarker'
import { useSpotsQuery } from '~/hooks/useSpotsQuery'

type Props = {
  defaultCenter?: Latlng | string | null
  defaultZoom?: number
  currentPosition: Latlng | undefined
  currentHeading: number
  onMoveCenter?: () => void
  onReloadCurrentTime?: () => void
  selectedSpot?: SelectedSpot
}

export function ImakoMap({
  defaultCenter,
  defaultZoom = 19,
  currentPosition,
  currentHeading,
  onMoveCenter,
  onReloadCurrentTime,
  selectedSpot,
}: Props) {
  const mapId = import.meta.env.VITE_GOOGLE_MAPS_ID
  const map = useMap()
  const [showTitle, setShowTitle] = useState(false)
  const navigate = useNavigate()
  const [currentBounds, setCurrentBounds] =
    useState<google.maps.LatLngBounds | null>(null)
  const { reloadFuncWithBbox, loading, data, previousBbox } = useSpotsQuery()

  const moveCenter = useCallback(() => {
    if (!currentPosition) return
    map?.setCenter(currentPosition)
    if (onMoveCenter) onMoveCenter()
  }, [currentPosition, map, onMoveCenter])

  const handleCameraChanged = useCallback(
    (ev: MapCameraChangedEvent) => {
      setShowTitle(ev.detail.zoom > 18)
    },
    [setShowTitle, map],
  )

  const onReloadButtonClick = useCallback(() => {
    if (!map) return
    reloadFuncWithBbox(false, map.getBounds())
    if (onReloadCurrentTime) onReloadCurrentTime()
  }, [onReloadCurrentTime, map])

  const handleIdle = useCallback(
    (e: MapEvent) => {
      const b = e.map.getBounds()
      if (!b) return
      if (b) setCurrentBounds(b)
      if (
        !previousBbox ||
        !previousBbox.contains(b.getNorthEast()) ||
        !previousBbox.contains(b.getSouthWest())
      )
        reloadFuncWithBbox(true, b)
    },
    [setCurrentBounds, reloadFuncWithBbox, previousBbox],
  )

  const handleClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  useEffect(() => {
    const tm = setTimeout(() => {
      if (map && selectedSpot?.lat && selectedSpot?.lng) {
        const bounds = map.getBounds()

        const ne = bounds?.getNorthEast()
        const sw = bounds?.getSouthWest()

        const isMd =
          'matchMedia' in window &&
          window.matchMedia('(min-width: 768px)').matches

        if (ne && sw) {
          const height = Math.abs(ne.lat() - sw.lat())
          const width = Math.abs(ne.lng() - sw.lng())

          // 地図の 1/4 の半分に表示するような微調整
          let lat = selectedSpot.lat - (height * 2.5) / 8.0
          let lng = selectedSpot.lng
          if (isMd) {
            lat = selectedSpot.lat
            lng = selectedSpot.lng - width / 8
          }
          map.panTo({ lat, lng })
        }
      }
    }, 300)
    return () => {
      clearTimeout(tm)
    }
  }, [map, selectedSpot])

  useEffect(() => {
    if (!map) return
    if (!defaultCenter) return

    if (typeof defaultCenter === 'string') {
      const [lat, lng] = defaultCenter.split(',')
      if (lat && lng) map.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) })
    } else {
      map.panTo(defaultCenter)
    }
    map.setZoom(defaultZoom)
  }, [map, defaultCenter, defaultZoom])

  return (
    <>
      {loading && (
        <div
          className={`bg-gray-400/50 absolute top-50 left-0 w-full h-full z-10 overflow-y-visible content-center text-center`}
        >
          <Spinner
            aria-label="Default status example"
            size="xl"
            className="z-20"
            color="success"
          />
        </div>
      )}
      <Map
        defaultCenter={GINZA_CENTER}
        defaultZoom={defaultZoom}
        disableDefaultUI={true}
        minZoom={12}
        mapId={mapId}
        clickableIcons={false}
        gestureHandling="greedy"
        headingInteractionEnabled={true}
        onCameraChanged={handleCameraChanged}
        onIdle={handleIdle}
        onClick={handleClick}
      >
        {currentPosition && (
          <CurrentPositionMarker
            currentPosition={currentPosition}
            currentHeading={currentHeading}
          />
        )}

        <MapControl position={ControlPosition.TOP_CENTER}>
          <Button
            color="gray"
            className="xw-fit h-6 border-0 p-0 mt-12 rounded-full drop-shadow-xl flex items-center justify-center"
            onClick={onReloadButtonClick}
          >
            <span className="text-xs">現在時刻で再取得する</span>
          </Button>
        </MapControl>

        <MapControl position={ControlPosition.RIGHT_BOTTOM}>
          <Button
            color="gray"
            className="ansolute w-10 h-10 bottom-10 right-5 rounded-full drop-shadow-xl flex items-center justify-center"
            onClick={moveCenter}
          >
            <FontAwesomeIcon icon={faLocationCrosshairs} />
          </Button>
        </MapControl>

        {data?.spots ? (
          <ClusteredSpotMarkers
            spots={data.spots}
            showTitle={showTitle}
            currentBounds={currentBounds}
          />
        ) : null}
      </Map>
    </>
  )
}
