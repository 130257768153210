import { FragmentType, graphql, useFragment } from '~/gql'

const spotsFragment = graphql(`
  fragment SpotsItem on Spot {
    code
    latitude
    longitude
    name
    isOpen
    crowdedLevel
    firstSpotPhoto {
      imageUrl
      thumbnailImageUrl
    }
  }
`)

export function useSpotsFragment(
  spotData: ReadonlyArray<FragmentType<typeof spotsFragment>>,
) {
  const spots = useFragment(spotsFragment, spotData)
  return { spots }
}
